<template>
  <div>
    <Section1 :keyWord="'La mejor solución para un préstamo con tu coche'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4">Préstamo por tu coche</h2>

      <h3 class="naranja">Préstamo por tu coche y sigue conduciéndolo.</h3>
      <p class="">Una opción viable cuando se necesita dinero urgentemente es conseguir un 
      préstamo por tu coche. <strong>Con <router-link to="/">Ibancar</router-link> puedes conseguir hasta 6.000€ en 24 horas.</strong> </p>
      <p class="">Siendo esta, una de las alternativas más usadas y populares actualmente, 
      ya que hay mayor probabilidad de que la solicitud sea aceptada. <strong>El procedimiento es muy sencillo, 
      seguro y rápido de completar.</strong> Sólo basta con rellenar el formulario y recibirás de inmediato una propuesta personalizada de préstamo.  </p>

      <h3 class="naranja">Préstamo con aval de coche Online</h3>
      <p class="">Los préstamos con aval de coche consisten en una financiación que posteriormente debes pagar con intereses. 
      <strong>La gran diferencia en <router-link to="/">Ibancar</router-link> es que el coche siempre será tuyo, todo el proceso es 100% online y no pagas alquiler por usar tu coche.</strong></p>
      <p class="">Además <strong>si estás inscrito en listas como  ASNEF o RAI no tiene importancia</strong>, siempre se puede solicitar un préstamo por tu coche sin problemas. 
      Este tipo de financiación es más flexible, en comparación a otras opciones. Y, como última característica, la tasación es totalmente gratuita y sin compromiso.</p>
      <p class="">Los requisitos son también una de las razones por las que es muy rentable este tipo de financiación. Ya que, para conseguirlo, <strong>tan solo necesitas: 
      ITV en vigor, pago del seguro al día, impuesto de circulación, algunas fotos del vehículo, ser propietario y el DNI. Si tienes la documentación, en el mismo día puedes conseguir el dinero que necesitas.</strong></p>

      <h3 class="naranja">Consigue un préstamo por tu coche y sigue conduciendo</h3>
      <p class="">Si necesitas conseguir un préstamo por tu coche, entonces tan solo debes rellenar un pequeño formulario en línea. Posteriormente, 
      recibirás una propuesta sin compromiso y se te contactará para realizar todo el trámite restante y así obtener tu dinero rápido, seguro y fácil. 
      <strong>¿Qué estás esperando? Después de realizar la financiación, podrás seguir conduciendo tu coche, solamente en IBANCAR es posible.</strong></p>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../minicreditos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Mini préstamos</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Minicréditos de Ibancar más económicos e igual de rápidos</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo con aval de coche sin cambio titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consige dinero con tu coche sin cambiar la titularidad</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo con aval de coche más de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Si tienes un coche de más de 10 años puedes conseguir dinero rápido</p>
              </div>
            </a>
          </div>
      </div>
<!-- fila landing 2  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en  Alicante? Tenemos la solución aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos por tu coche en Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar la titularidad</p>
              </div>
            </a>
          </div>
        <div class="card m-4"  >
            <a href="../prestamo-rapido" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo rápido</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue hasta 6.000€ hoy mismo de forma rápida y sencilla.</p>
              </div>
            </a>
          </div>
      </div>
<!-- fila landing 3  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina Rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con ASNEF de manera sencilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../micro-prestamos-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Micro préstamos con ASNEF</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue desde 300€ de forma rápida con la garantía de tu coche</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-particulares-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos particulares con ASNEF</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Si estás en ASNEF, puedes conseguir un préstamo con nosotros</p>
              </div>
            </a>
          </div>
      </div>

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamo-1000-euros-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo 1000€ Asnef</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue 1.000€ hoy mismo, aunque esté en ASNEF</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../minicreditos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Mini Créditos</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Minicréditos de Ibancar más económicos e igual de rápidos</p>
              </div>
            </a>
          </div>
          <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval</p>
              </div>
            </a>
          </div>
      </div>
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-las-palmas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval coche Las Palmas</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar la titularidad</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-por-tu-coche-en-barcelona" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval coche Barcelona</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Dinero por tu coche, préstamo por tu coche, empeñar tu coche, hipoteca tu coche</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-por-tu-coche-en-madrid" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval coche <br>Madrid</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en madrid</p>
              </div>
            </a>
          </div>
      </div>

<!-- fila landing 6  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-por-tu-coche-en-sevilla" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval coche Sevilla</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval coche Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval coche Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en ASNEF</p>
              </div>
            </a>
          </div>
      </div>

<!-- fila landing 7  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamo-plazos-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos a plazos rápidos online</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue hasta 6.000€ y devuélvelos en cómodos plazos</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamo-rapido-1500-euros" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Consigue 1500€ con nuestro préstamo rápido</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Recibe los 1.500€ que necesitas hoy, fácil y rápido.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamo-rapido-2000-euros" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Consigue 2000€ con nuestro préstamo rápido</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Recibe los 2.000€ que necesitas hoy, fácil y rápido.</p>
              </div>
            </a>
          </div>
      </div>

<!-- fila landing 8  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamo-rapido-3000-euros" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Consigue 3000€ con nuestro préstamo rápido</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Recibe los 3000€ que necesitas hoy, fácil y rápido.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-5000-euros" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Consigue 5000€ con nuestro préstamo rápido</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Recibe los 5000€ que necesitas hoy, fácil y rápido.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamo-rapido-6000-euros" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Consigue 6000€ con nuestro préstamo rápido</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Recibe los 6000€ que necesitas hoy, fácil y rápido.</p>
              </div>
            </a>
          </div>

      </div>
<!-- fila landing 9 -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a href="../prestamos-urgentes-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos urgentes con asnef</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo urgente si te encuentras en asnef</p>
              </div>
            </a>
        </div>
        <div class="card m-4">
          <a href="../prestamos-bodas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para bodas</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para bodas</p>
              </div>
            </a>
        </div>
        <div class="card m-4">
          <a href="../prestamo-reformar-vivienda" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para reformar vivienda</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para reformar tu vivienda</p>
              </div>
            </a>
        </div>
      </div>
<!-- fila landing 10 -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a href="../prestamo-iniciar-negocio" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para iniciar negocio</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para iniciar tu nuevo negocio</p>
              </div>
            </a>
        </div>
        <div class="card m-4">
          <a href="../prestamo-reunificar-deudas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para reunificar deudas</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para reunificar tus deudas</p>
              </div>
            </a>
        </div>
        <div class="card m-4">
          <a href="../prestamo-personal" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos personal</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ibancar te puede ofrecer la asistencia financiera que necesitas.</p>
              </div>
            </a>
        </div>
      </div>
      <!-- fila landing 11-->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a href="../prestamo-docentes" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para docentes</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para docentes</p>
              </div>
            </a>
        </div>
        <div class="card m-4">
          <a href="../prestamo-dentista" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para dentista</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para el dentista</p>
              </div>
            </a>
        </div>
        <div class="card m-4">
          <a href="../prestamo-autonomos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para Autonomos</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para Autonomos</p>
              </div>
            </a>
        </div>
      </div>
            <!-- fila landing 12-->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4">
          <a href="../prestamo-placas-solares" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos para placas solares</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un prestamo para placas solares</p>
              </div>
            </a>
        </div>
      </div>
            

      <!-- botón -->

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamo con tu Coche como Aval',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        // {name:'description', content:'Préstamo con aval de coche sin cambio de titularidad. Consigue crédito hasta 6.000€ en 24h. Evita rentings y amortiza desde el primer día. Consigue dinero rápido.'},
        {name:'description', content:'¿Necesitas una financiación urgentemente? Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.'},
        // {name:'keywords', content:'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.'}
        {name:'keywords', content:'Préstamo por tu coche, Préstamo con aval de coche online, Préstamo por tu coche y sigue conduciendo.'}
      ],
      link:[
        {href: 'https://ibancar.com/prestamo-por-tu-coche', rel:'alternate', hreflang:'es-es'},
        {href: 'https://ibancar.mx/prestamos-sobre-tu-auto-en-ibancar', rel:'alternate', hreflang:'es-MX'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>